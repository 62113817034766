<!-- 数据图表 -->
<template>
  <div class="writePage">
    <div class="box">
      <Panel :panelData="panelData" class="panel" @clickpanel="clickpanel" />
      <div class="echartBox1">
        <Title value="下单金额汇总" />
        <div id="echart-one"></div>
      </div>
      <div class="echartBox2">
        <div class="echart1">
          <Title value="平均住院天数及住院费用" />
          <div id="echart-two"></div>
        </div>
        <div class="echart2">
          <Title value="性别比例" />
          <div id="echart-line"></div>
        </div>
      </div>
      <div class="echartBox3">
        <div class="echart1">
          <Title value="上报趋势" />
          <div id="echart-sex"></div>
        </div>

        <div class="echart2">
          <Title value="年龄分布" />
          <div id="echart-pie"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "./components/Panel.vue";
import Title from "@/components/filters/Title.vue";
import chartData from "./data";
import * as echarts from "echarts";

export default {
  components: {
    Panel,
    Title,
  },
  data() {
    return {
      //面板数据
      panelData: [
        { title: "今日上报门店数量：", num: 2, color: "#8C86FF" },
        { title: "昨日上报门店数量：", num: 24, color: "#298AFB" },
        { title: "上月上报门店数量：", num: 563, color: "#F5BE15" },
        { title: "累计审核成功数量：", num: 2, color: "#76D4F9" },
      ],
    };
  },
  computed: {
    chartData,
  },
  mounted() {
    var chartDom1 = document.getElementById("echart-one");
    var myChart1 = echarts.init(chartDom1);
    this.chartData[0] && myChart1.setOption(this.chartData[0]);

    var chartDom2 = document.getElementById("echart-two");
    var myChart2 = echarts.init(chartDom2);
    this.chartData[1] && myChart2.setOption(this.chartData[1]);

    var chartDom3 = document.getElementById("echart-sex");
    var myChart3 = echarts.init(chartDom3);
    this.chartData[2] && myChart3.setOption(this.chartData[2]);

    var chartDom4 = document.getElementById("echart-line");
    var myChart4 = echarts.init(chartDom4);
    this.chartData[3] && myChart4.setOption(this.chartData[3]);

    var chartDom5 = document.getElementById("echart-pie");
    var myChart5 = echarts.init(chartDom5);
    this.chartData[4] && myChart5.setOption(this.chartData[4]);
  },
  created() {},
  methods: {
    clickpanel(item) {
      // console.log("item=========", item);
      this.$router.push({ name: "DiseaseList" });
    },
  },
};
</script>

<style scoped lang="less">
.panel {
  width: 100%;
  margin-bottom: 10px;
}

.echartBox1 {
  background-color: #fff;
  margin: 0px 0 20px;
  padding: 50px 50px 0;
  #echart-one {
    width: 100%;
    height: 250px;
  }
}

.echartBox2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .echart1 {
    padding: 50px;
    width: 50%;
    margin-right: 20px;
    background-color: #fff;
  }
  .echart2 {
    padding: 50px;
    width: 50%;
    background-color: #fff;
  }
  #echart-two,
  #echart-line {
    width: 100%;
    height: 250px;
  }
}

.echartBox3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  .echart1 {
    margin-right: 20px;
    padding: 50px;
    width: 65%;
    background-color: #fff;
  }
  .echart2 {
    // margin: 0px 10px 20px;
    padding: 50px;
    width: 35%;
    background-color: #fff;
  }
  #echart-sex,
  #echart-pie {
    width: 100%;
    height: 250px;
  }
}
</style>
