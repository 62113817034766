<!-- 目录 -->
<template>
  <div class="panelData">
    <li
      :style="{ borderLeftColor: item.color }"
      v-for="(item, index) in panelData"
      :key="index"
      @click="itemBtn(item, index)"
    >
      <div class="title">
        {{ item.title }}
      </div>
      <div class="num" :style="{ color: item.color }">{{ item.num }}</div>
    </li>
  </div>
</template>

<script>
export default {
  name: "Panel",
  components: {},
  props: {
    panelData: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    itemBtn(item, index) {
      console.log(item, index);
      this.$emit("clickpanel", item);
      // this.active = index;
    },
  },
};
</script>

<style scoped lang="less">
.panelData {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  li {
    border-radius: 6px;
    padding: 10px 10px;
    background: #ffffff;
    width: 24%;
    min-width: 300px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-left: 3px solid #fff;
    margin-bottom: 10px;
    .title {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
    .num {
      // border: 1px solid red;
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      font-weight: bold;
      font-size: 28px;
    }
  }
  li:hover {
    cursor: pointer;
  }
}
</style>
