export default function() {
  /* 图表格渲染数据 */
  return [
    /* 柱形图配置  */
    {
      legend: {},
      tooltip: {},
      xAxis: {
        type: "category",
        data: [
          "STEMI",
          "HF",
          "CABG",
          "AF",
          "AF",
          "AF",
          "AF",
          "CABG",
          "AF",
          "AF",
        ],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
      },
      series: [
        {
          // name: "脑梗死(首次住院)",
          type: "bar",
          stack: "one",
          data: [
            { value: 70, name: "脑梗死(首次住院)", count: 200 },
            { value: 10, name: "脑梗死(首次住院)", count: 20 },
            { value: 30, name: "脑梗死(首次住院)", count: 10 },
            { value: 20, name: "脑梗死(首次住院)", count: 300 },
            { value: 20, name: "脑梗死(首次住院)", count: 400 },
            { value: 50, name: "脑梗死(首次住院)", count: 500 },
            { value: 80, name: "脑梗死(首次住院)", count: 200 },
            { value: 70, name: "脑梗死(首次住院)", count: 100 },
            { value: 70, name: "脑梗死(首次住院)", count: 2200 },
            { value: 100, name: "脑梗死(首次住院)", count: 2300 },
          ],
          color: ["#5B8FF9"], //设置同一类柱子不同颜色
          //设置柱子的宽度
          barWidth: 30,
          //配置样式
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function(params) {
                // var colorList = ["#5b7bda", "#5b7bda"];
                // return colorList[params.dataIndex];
                return "#5B8FF9";
              },
            },
            //鼠标悬停时：
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      //下面是悬浮框的设置
      tooltip: {
        // trigger: "axis",
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        //下面是自定义悬浮框的部分，修改悬浮框的样式也是在这里，就是html的样式，自由发挥。
        formatter: function(params) {
          // console.log("params", params.data);
          var res =
            "<div style='font-weight: 500;color: rgba(0,0,0,0.88);' >" +
            params.data.name +
            ":</div>" +
            "完成率: " +
            params.data.value +
            "%" +
            "<br/>" +
            "上报数量:" +
            params.data.count;
          return res;
        },
      },
    },
    /* 柱形图+折线图 */
    {
      legend: {},
      tooltip: {},
      xAxis: {
        type: "category",
        data: [
          "STEMI",
          "HF",
          "CABG",
          "AF",
          "AF",
          "AF",
          "AF",
          "CABG",
          "AF",
          "AF",
        ],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} 天",
        },
      },
      series: [
        {
          // name: "脑梗死(首次住院)",
          type: "bar",
          stack: "one",
          data: [
            { value: 70, name: "脑梗死(首次住院)", count: 200 },
            { value: 10, name: "脑梗死(首次住院)", count: 20 },
            { value: 30, name: "脑梗死(首次住院)", count: 10 },
            { value: 20, name: "脑梗死(首次住院)", count: 300 },
            { value: 20, name: "脑梗死(首次住院)", count: 400 },
            { value: 50, name: "脑梗死(首次住院)", count: 500 },
            { value: 80, name: "脑梗死(首次住院)", count: 200 },
            { value: 70, name: "脑梗死(首次住院)", count: 100 },
            { value: 70, name: "脑梗死(首次住院)", count: 2200 },
            { value: 100, name: "脑梗死(首次住院)", count: 2300 },
          ],
          color: ["#76D4F9"], //设置同一类柱子不同颜色
          //设置柱子的宽度
          barWidth: 30,
          //配置样式
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function(params) {
                // var colorList = ["#5b7bda", "#5b7bda"];
                // return colorList[params.dataIndex];
                return "#76D4F9";
              },
            },
            //鼠标悬停时：
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          // name: "Email",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101],
          color: "#F5BE15",
        },
      ],
      //下面是悬浮框的设置
      tooltip: {
        // trigger: "axis",
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        //下面是自定义悬浮框的部分，修改悬浮框的样式也是在这里，就是html的样式，自由发挥。
        // formatter: function(params) {
        //   // console.log("params", params.data);
        //   var res =
        //     "<div style='font-weight: 500;color: rgba(0,0,0,0.88);' >" +
        //     params.data.name +
        //     ":</div>" +
        //     "完成率: " +
        //     params.data.value +
        //     "%" +
        //     "<br/>" +
        //     "上报数量:" +
        //     params.data.count;
        //   return res;
        // },
      },
    },
    /* 柱形图配置 男女比例 */
    {
      legend: {},
      tooltip: {},
      xAxis: {
        type: "category",
        data: [
          "STEMI",
          "HF",
          "CABG",
          "AF",
          "AF",
          "AF",
          "AF",
          "CABG",
          "AF",
          "AF",
        ],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
      },
      series: [
        {
          // name: "脑梗死(首次住院)",
          type: "bar",
          stack: "one",
          data: [
            { value: 57, name: "男性" },
            { value: 90, name: "男性" },
            { value: 70, name: "男性" },
            { value: 80, name: "男性" },
            { value: 80, name: "男性" },
            { value: 50, name: "男性" },
            { value: 20, name: "男性" },
            { value: 30, name: "男性" },
            { value: 30, name: "男性" },
            { value: 0, name: "男性" },
          ],
          color: ["#64779B"], //设置同一类柱子不同颜色
          //设置柱子的宽度
          barWidth: 30,
          //配置样式
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function(params) {
                // var colorList = ["#5b7bda", "#5b7bda"];
                // return colorList[params.dataIndex];
                return "#64779B";
              },
            },
            //鼠标悬停时：
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          // name: "脑梗死(首次住院)",
          type: "bar",
          stack: "one",
          data: [
            { value: 43, name: "女性" },
            { value: 10, name: "女性" },
            { value: 30, name: "女性" },
            { value: 20, name: "女性" },
            { value: 20, name: "女性" },
            { value: 50, name: "女性" },
            { value: 80, name: "女性" },
            { value: 70, name: "女性" },
            { value: 70, name: "女性" },
            { value: 100, name: "女性" },
          ],
          color: ["#EE8CB7"], //设置同一类柱子不同颜色
          //设置柱子的宽度
          barWidth: 30,
          //配置样式
          itemStyle: {
            //通常情况下：
            normal: {
              //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
              color: function(params) {
                // var colorList = ["#5b7bda", "#5b7bda"];
                // return colorList[params.dataIndex];
                return "#EE8CB7";
              },
            },
            //鼠标悬停时：
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      //下面是悬浮框的设置
      tooltip: {
        // trigger: "axis",
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        //下面是自定义悬浮框的部分，修改悬浮框的样式也是在这里，就是html的样式，自由发挥。
        formatter: function(params) {
          // console.log("params", params.data);
          var res =
            "<div style='font-weight: 500;color: rgba(0,0,0,0.88);' >" +
            "脑梗死(首次住院)" +
            ":</div>" +
            params.data.name +
            params.data.value +
            "%";
          return res;
        },
      },
    },
    /* 折线图 */
    {
      title: {
        // text: "Stacked Line",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        // data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          // name: "Email",
          type: "line",
          stack: "Total",
          data: [
            120,
            132,
            101,
            134,
            90,
            230,
            210,
            120,
            132,
            101,
            134,
            90,
            230,
            210,
          ],
          color: "#6F5EFC",
        },
      ],
    },
    /* 扇形图配置 */
    {
      title: {
        // text: "数据报表",
        // subtext: "User Data",
        left: "center",
      },
      color: ["#6F5EFC", "#5B8FF9", "#60DCAB", "#EE8CB7", "#F5BE15", "#F4903D"],
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "right",
      },
      series: [
        {
          name: "脑梗死(首次住院)",
          type: "pie",
          // radius: "50%",
          center: ["30%", "50%"], //修改圆心位置
          radius: ["40%", "70%"],
          data: [
            { value: 1048, name: "0～10岁：" },
            { value: 580, name: "10～20岁：" },
            { value: 580, name: "20～35岁：" },
            { value: 580, name: "35～50岁：" },
            { value: 580, name: "50～70岁：" },
            { value: 580, name: "70+岁：" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            normal: {
              show: false,
            },
          },
        },
      ],
    },
  ];
}
